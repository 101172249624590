<template>
    <admin-dashboard-layout>
        <div class="mb-2">
            <v-row>
                <v-col sm="7" class="d-flex align-center">
                    <v-text-field
                        v-model="search"
                        prepend-inner-icon="mdi-magnify"
                        label="Search by plan name or id"
                        single-line
                        hide-details
                        class="mr-2"
                        outlined
                        dense
                        clearable
                        v-on:keydown.enter="handleSearch"
                    ></v-text-field>
                    <v-btn height="40" @click="handleSearch" color="primary" elevation="0" >
                        Search
                    </v-btn>
                </v-col>
                <v-col sm="5" class="d-flex align-end">
                    <v-btn height="40" outlined color="primary" @click="$router.push({name: 'admin.price-plans.create'})">
                        <v-icon left>mdi-plus</v-icon>
                        Create new plan
                    </v-btn>
                </v-col>
            </v-row>
            
        </div>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="pricePlans"
            :items-per-page="20"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ $date(item.created_at).format('MMMM D, YYYY')}} <!--TODO add day.js and default format -->
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
    import AdminApi from "@/api/AdminApi";

    export default {
        name: "PricePlansList",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                pricePlans: [],
                loading: false,
                search: '',
                headers: [
                    {
                        text: '# ID',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'Name', value: 'name' },
                    { text: 'Users With This Plan', value: 'users_count', sortable: true},
                    { text: 'Created At', value: 'created_at', sortable: true},
                ],
            }
        },
        watch: {
            '$route.query': {
                immediate: true,
                handler() {
                    this.search = this.$route.query.search || '';
                    this.fetchPlans(this.search);
                }
            }
        },
        methods: {
            handleClickRow(plan) {
                this.$router.push({name: 'admin.price-plans.edit', params: {id: plan.id}})
            },
            async fetchPlans(search){
                this.loading = true
                this.fetchRes = await AdminApi.getPricePlans(search);
                if(this.fetchRes && this.fetchRes.data){
                    this.pricePlans = this.fetchRes.data || []
                }
                this.loading = false
            },
            handleSearch(){
                // Update the URL with the search query
                this.$router.push({
                    name: this.$route.name,
                    query: { 
                        ...this.$route.query,
                        search: this.search
                    }
                });
            },
        },
    }
</script>

<style scoped>

</style>
